import { Grid, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

export const MyJobSkeleton = () => (
  <Stack>
    <Grid container alignItems={'center'} justifyContent={'space-between'}>
      <Skeleton
        variant='rectangular'
        width={`100px`}
        height={22}
        sx={{ marginBottom: `16px` }}
      />
      <Skeleton
        variant='rectangular'
        width={`66px`}
        height={22}
        sx={{ marginBottom: `16px` }}
      />
    </Grid>
    <Skeleton variant='rectangular' width={`100%`} height={1} />
    <Grid container mt={2} gap={2} flexWrap={'nowrap'}>
      <Grid item sx={{ width: `30%` }}>
        <Grid container gap={1} alignItems={'center'}>
          <Skeleton variant='circle' width={4} height={4} />
          <Grid item xs>
            <Skeleton variant='rectangular' width={`70%`} height={21} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Skeleton variant='rectangular' width={`50%`} height={21} />
      </Grid>
    </Grid>
    <Grid container mt={2} gap={2} flexWrap={'nowrap'}>
      <Grid item sx={{ width: `30%` }}>
        <Grid container gap={1} alignItems={'center'}>
          <Skeleton variant='circle' width={4} height={4} />
          <Grid item xs>
            <Skeleton variant='rectangular' width={`70%`} height={21} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Skeleton variant='rectangular' width={`50%`} height={21} />
      </Grid>
    </Grid>
    <Grid container mt={2} mb={2} gap={2} flexWrap={'nowrap'}>
      <Grid item sx={{ width: `30%` }}>
        <Grid container gap={1} alignItems={'center'}>
          <Skeleton variant='circle' width={4} height={4} />
          <Grid item xs>
            <Skeleton variant='rectangular' width={`70%`} height={21} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Skeleton variant='rectangular' width={`50%`} height={21} />
      </Grid>
    </Grid>
    <Skeleton variant='rectangular' width={`100%`} height={1} />
    <Grid container mt={2} gap={2} flexWrap={'nowrap'}>
      <Grid item sx={{ width: `30%` }}>
        <Grid container gap={1} alignItems={'center'}>
          <Skeleton variant='circle' width={4} height={4} />
          <Grid item xs>
            <Skeleton variant='rectangular' width={`70%`} height={21} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Skeleton variant='rectangular' width={`50%`} height={21} />
      </Grid>
    </Grid>
    <Grid container mt={2} gap={2} flexWrap={'nowrap'}>
      <Grid item sx={{ width: `30%` }}>
        <Grid container gap={1} alignItems={'center'}>
          <Skeleton variant='circle' width={4} height={4} />
          <Grid item xs>
            <Skeleton variant='rectangular' width={`70%`} height={21} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Skeleton variant='rectangular' width={`50%`} height={21} />
      </Grid>
    </Grid>
    <Skeleton
      variant='rectangular'
      width={`100%`}
      height={90}
      sx={{ marginTop: `16px` }}
    />
  </Stack>
);

import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import App from '@/App';
import rootReducer from '@/store';
import config from "@/config";

const isDev = config.env.toLowerCase() === 'development';
const isLocal = config.env.toLowerCase() === 'local';
const composeEnhancers = composeWithDevTools({trace: true, traceLimit: 25});

const store = createStore(
  rootReducer,
  isDev || isLocal ? composeEnhancers(applyMiddleware(thunk)) : applyMiddleware(thunk)
);

const Root = () => (
  <Provider store={store}>
    <Router basename={'/'}>
      <App/>
    </Router>
  </Provider>
);

export default Root;

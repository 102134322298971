import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PurgeStatus from '@/components/common/statuses/PurgeStatus';
import { Divider, Grid, List, ListItemText, ListItem, Typography } from '@mui/material';
import { alpha, Box, Stack } from '@mui/system';
import FileObjectName from '@/components/manage/job/common/FileObjectName';
import { CircleProgress, LinearProgress } from '@mzc-cloudplex/core';
import theme from '@/styles/theme';
import { IconCompleteCircle, IconErrorCircle } from '@mzc-cloudplex/icons';
import PropTypes from 'prop-types';
import Overview from '@/components/manage/common/Overview';
import UserInfo from '@/components/common/UserInfo';
import ProjectName from '@/components/myJob/tabs/ProjectName';
import CdnIconName from '@/components/common/CdnIconName';

const PurgeJobOverview = ({ spaceId, job, targetCdns, requesterVisible }) => {
  const { t } = useTranslation('jobs');

  const progressRate =
    (job.resultInfo.completedCount + job.resultInfo.failedCount) / job.resultInfo.totalCount;

  return (
    <Overview>
      <Grid container flexWrap={`nowrap`} sx={{ p: `24px 4px` }}>
        <Grid item xs={4}>
          <List disablePadding>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant={'body2'} color={theme.palette.secondary[600]} mb={1}>
                    {t('object')}
                  </Typography>
                }
                secondary={
                  <FileObjectName
                    path={job.name}
                    name={job.isPurgeAll() ? t('purgeAll') : null}
                    total={job.fileObjectCount}
                    variant={'overview'}
                  />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant={'body2'} color={theme.palette.secondary[600]} mb={1}>
                    {t('jobId')}
                  </Typography>
                }
                secondary={job.id}
              />
            </ListItem>
          </List>
        </Grid>
        <Divider flexItem orientation={'vertical'} sx={{ margin: `0 10px` }} />
        <Grid item xs={4}>
          <List disablePadding>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant={'body2'} color={theme.palette.secondary[600]} mb={1}>
                    {t('project')}
                  </Typography>
                }
                secondary={<ProjectName spaceId={spaceId} projectId={job.project.id} />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant={'body2'} color={theme.palette.secondary[600]} mb={1}>
                    CDN(s)
                  </Typography>
                }
                secondary={
                  <>
                    <Grid container gap={0.5}>
                      {targetCdns.map((cdn) => (
                        <Grid item key={cdn.id}>
                          <CdnIconName service={cdn.service} />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                }
              />
            </ListItem>
          </List>
        </Grid>
        <Divider flexItem orientation={'vertical'} sx={{ margin: `0 10px` }} />
        <Grid item xs={4}>
          <List disablePadding>
            <ListItem>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant={'body2'} color={theme.palette.secondary[600]} mb={1}>
                    {t('requestedAt')}
                  </Typography>
                }
                secondary={
                  <>
                    {requesterVisible ? (
                      <Grid container alignItems={'center'}>
                        <Trans
                          i18nKey={`jobs::message.requestedBy`}
                          values={{
                            name: `${job.creator.name}`,
                            createdAt: `${job.createdAt}`
                          }}
                          components={[<UserInfo user={job.creator} />]}
                        />
                      </Grid>
                    ) : (
                      `${job.createdAt}`
                    )}
                  </>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant={'body2'} color={theme.palette.secondary[600]} mb={1}>
                    {t('completedAt')}
                  </Typography>
                }
                secondary={job.completedAt ? job.completedAt : '-'}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <Grid
        container
        gap={`44px`}
        mb={2}
        alignItems={'center'}
        sx={{
          backgroundColor: alpha(theme.palette.grayscaleSecondary[50], 0.5),
          padding: `12px 16px`,
          borderRadius: `2px`
        }}
      >
        <Grid item>
          <PurgeStatus status={job.resultInfo.status} />
        </Grid>
        <Grid item xs>
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography variant={'body2'} fontWeight={'bold'} color={'primary'}>
              {job.resultInfo.completedCount + job.resultInfo.failedCount}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              / {job.resultInfo.totalCount}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {t('task')}{' '}
              {job.resultInfo.status === 'IN_PROGRESS'
                ? t('inProgress')
                : job.resultInfo.status === 'PREPARED'
                  ? t('pending')
                  : t('completed')}
            </Typography>
          </Box>
          <Box pt={1} pb={1.5}>
            <LinearProgress
              height={6}
              value={isNaN(progressRate) ? 0 : progressRate * 100}
              variant='determinate'
            />
          </Box>
          <Stack direction='row' justifyContent='flex-start' spacing={2} component={'div'}>
            {job.resultInfo.pendingCount > 0 && (
              <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
                <CircleProgress size={12} color={'inherit'} thickness={5} />
                <Grid item>
                  <Typography variant={'body2'} color={theme.palette.text.secondary}>
                    <strong>{job.resultInfo.pendingCount}</strong> {t('pending')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {job.resultInfo.inProgressCount > 0 && (
              <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
                <CircleProgress size={12} color={'primary'} thickness={5} />
                <Grid item>
                  <Typography variant={'body2'} color={theme.palette.primary.main}>
                    <strong>{job.resultInfo.inProgressCount}</strong> {t('inProgress')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {job.resultInfo.completedCount > 0 && (
              <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
                <IconCompleteCircle size={12} color={'success'} />
                <Grid item>
                  <Typography variant={'body2'} color={theme.palette.success.main}>
                    <strong>{job.resultInfo.completedCount}</strong> {t('completed')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {job.resultInfo.failedCount > 0 && (
              <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
                <IconErrorCircle size={12} color={'error'} />
                <Grid item>
                  <Typography variant={'body2'} color={theme.palette.error.main}>
                    <strong>{job.resultInfo.failedCount}</strong> {t('failed')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Overview>
  );
};

PurgeJobOverview.propTypes = {
  spaceId: PropTypes.string.isRequired,
  job: PropTypes.object.isRequired
};

export default PurgeJobOverview;

import { AREA_TYPES, areas, getGeolocation } from '@/services/areas';
import { activityService } from '@/services/activityService';

export const ROUTING_POLICY_V1 = 'ROUTING_POLICY_V1';

export const ROUTING_TYPES = {
  SIMPLE: 'SIMPLE',
  WEIGHTED: 'WEIGHTED',
  GEOCUSTOM: 'GEOCUSTOM'
};

export const DEFAULT_CODE = 'DEFAULT';
export const DEFAULT_CODE_TYPE = 'DEFAULT';

export const routingPolicyService = {
  getCdnsFromRoutingPolicy: (routingPolicy) => {
    const cdns = routingPolicy?.trafficRoutes
      ?.flatMap((trafficRoute) =>
        trafficRoute.weights.flatMap((weight) => [weight.cdn, weight.failover?.cdn])
      )
      .filter((item) => item != null);
    return cdns?.filter((cdn, index) => cdns.indexOf((cdn2) => cdn2.id === cdn.id) !== index) ?? [];
  },
  createCopyModel: (routingPolicy, cdns) =>
    ({
      name: `Copy of ` + routingPolicy.name,
      alias: routingPolicy.alias,
      type: routingPolicy.type,
      trafficRoutes: routingPolicy?.trafficRoutes?.map((trafficRoute) => {
        const totalWeight = trafficRoute.weights.reduce(
          (acc, weightObject) => acc + weightObject.weight,
          0
        );
        const area = getGeolocation({
          code: trafficRoute.code,
          codeType: trafficRoute.codeType
        });
        return {
          code: trafficRoute.code,
          codeType: trafficRoute.codeType,
          label: area?.countryLabel != null ? area?.countryLabel : area?.continentLabel,
          continent:
            trafficRoute.codeType === AREA_TYPES.COUNTRY
              ? {
                  code: area?.continentCode,
                  label: area?.continentLabel
                }
              : null,
          weightObjects: trafficRoute.weights.map((weightObject) => {
            const cdn = cdns.find((cdn) => cdn.id === weightObject.cdn.id);
            const failoverCdn = cdns.find((cdn) => cdn.id === weightObject.failover?.cdn?.id);
            return {
              ...weightObject,
              value: weightObject.weight,
              weightPerTotal: totalWeight === 0 ? 0 : (weightObject.weight * 100) / totalWeight,
              cdn: {
                id: cdn.id,
                service: cdn.service,
                name: cdn?.provider?.data?.serviceName
              },
              failover:
                failoverCdn == null
                  ? null
                  : {
                      cdn: {
                        id: failoverCdn.id,
                        service: failoverCdn.service,
                        name: failoverCdn.provider?.data?.serviceName
                      }
                    }
            };
          })
        };
      })
    }) ?? [],
  createApiModel: (routingPolicy, viewModel) => ({
    ...routingPolicy,
    trafficRoutes: viewModel.trafficRoutes.map((trafficRoute) => ({
      code: trafficRoute.code,
      codeType: trafficRoute.codeType,
      weights: trafficRoute.weights.map((weightObject) => ({
        weight: weightObject.value,
        cdn: {
          id: weightObject.cdn.id
        },
        failover:
          weightObject.failover == null
            ? null
            : {
                cdn: {
                  id: weightObject.failover.cdn.id
                }
              }
      }))
    }))
  }),
  createViewModel: (routingPolicy, cdns) => ({
    ...routingPolicy,
    trafficRoutes:
      routingPolicy?.trafficRoutes?.map((trafficRoute) => {
        const totalValue = trafficRoute.weights.reduce(
          (acc, weightObject) => acc + weightObject.weight,
          0
        );
        return {
          ...trafficRoute,
          code: trafficRoute.code,
          codeType: trafficRoute.codeType,
          weights: trafficRoute.weights.map((weightObject) => {
            const cdn = cdns.find((cdn) => cdn.id === weightObject.cdn.id);
            const failoverCdn = cdns.find((cdn) => cdn.id === weightObject.failover?.cdn?.id);
            return {
              ...weightObject,
              value: weightObject.weight,
              weightPerTotal: totalValue === 0 ? 0 : (weightObject.weight * 100) / totalValue,
              cdn: {
                id: cdn?.id,
                service: cdn?.service,
                name: cdn?.provider?.data?.serviceName
              },
              failover:
                failoverCdn == null
                  ? null
                  : {
                      cdn: {
                        id: failoverCdn?.id,
                        service: failoverCdn?.service,
                        name: failoverCdn?.provider?.data?.serviceName
                      }
                    }
            };
          })
        };
      }) ?? [],
    ...activityService.createViewModel(routingPolicy)
  }),
  validateWeightValue: (value) => {
    const range = (value) => {
      if (value < 0) return 0;
      if (value > 255) return 255;
      return value;
    };
    return range(parseInt(value) || 0);
  },
  getAreaByCode: (codeType, code) => {
    if (codeType === AREA_TYPES.CONTINENT) {
      const continent = areas.find((area) => area.code === code);
      return {
        continent: {
          codeType: continent.codeType,
          code: continent.code,
          label: continent.label
        }
      };
    }
    if (codeType === AREA_TYPES.COUNTRY) {
      for (let continent of areas) {
        const country = continent.countries.find((country) => country.code === code);
        if (country != null) {
          return {
            continent: {
              codeType: continent.codeType,
              code: continent.code,
              label: continent.label
            },
            country: {
              codeType: country.codeType,
              code: country.code,
              label: country.label
            }
          };
        }
      }
    }
  }
};

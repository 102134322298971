import React from 'react';
import { StyledStatus, StyledStatusText } from '@/components/common/statuses/status.styled';
import {
  IconCheck,
  IconCompleteCircle,
  IconErrorCircle,
  IconCircleOutlined,
  IconWarning
} from '@mzc-cloudplex/icons';
import { COMPARE_JOB_STATUSES } from '@/services/job/compareJobService';
import theme from '@/styles/theme';
import { Typography } from '@mui/material';
import { CircleProgress } from '@mzc-cloudplex/core';
import { useTranslation } from 'react-i18next';

const CompareStatus = ({ status }) => {
  const { t } = useTranslation('jobs');
  return (
    <>
      {status === COMPARE_JOB_STATUSES.PREPARED && <StyledStatus label={t('prepared')} />}
      {status === COMPARE_JOB_STATUSES.ALL_IDENTICAL && (
        <StyledStatus
          label={t('completed')}
          color={'success'}
          icon={<IconCheck size={12} htmlColor={'#fff'} />}
        />
      )}
      {status === COMPARE_JOB_STATUSES.INCLUDE_DIFFERENT && (
        <>
          <StyledStatus
            label={t('completed')}
            color={'success'}
            icon={<IconCheck size={12} htmlColor={'#fff'} />}
          />
          <IconErrorCircle size={16} color={'warning'} />
        </>
      )}
      {status === COMPARE_JOB_STATUSES.ALL_FAILED && (
        <StyledStatus
          label={t('completed')}
          color={'error'}
          icon={<IconWarning size={12} htmlColor={'#fff'} />}
        />
      )}
      {status === COMPARE_JOB_STATUSES.IN_PROGRESS && (
        <StyledStatus label={t('inProgress')} color={'primary'} />
      )}
      {status === COMPARE_JOB_STATUSES.INCLUDE_FAILED && (
        <>
          <StyledStatus
            label={t('completed')}
            color={'success'}
            icon={<IconCheck size={12} htmlColor={'#fff'} />}
          />
          <IconErrorCircle size={16} color={'error'} />
        </>
      )}
    </>
  );
};

export const CompareTextStatus = ({ status }) => {
  const { t } = useTranslation('jobs');
  return (
    <>
      {status === COMPARE_JOB_STATUSES.PREPARED && (
        <StyledStatusText>
          <IconCircleOutlined size={12} htmlColor={theme.palette.grayscaleSecondary[300]} />
          <Typography color={theme.palette.grayscaleSecondary[300]}>{t('prepared')}</Typography>
        </StyledStatusText>
      )}
      {status === COMPARE_JOB_STATUSES.ALL_IDENTICAL && (
        <StyledStatusText>
          <IconCompleteCircle size={12} color={'success'} />
          <Typography color={theme.palette.success.main}>{t('completed')}</Typography>
        </StyledStatusText>
      )}
      {status === COMPARE_JOB_STATUSES.INCLUDE_DIFFERENT && (
        <>
          <StyledStatusText>
            <IconCompleteCircle size={12} color={'success'} />
            <Typography color={theme.palette.success.main}>{t('completed')}</Typography>
            <IconErrorCircle size={12} color={'error'} />
          </StyledStatusText>
        </>
      )}
      {status === COMPARE_JOB_STATUSES.ALL_FAILED && (
        <StyledStatusText>
          <IconErrorCircle size={12} color={'error'} />
          <Typography color={theme.palette.error.main}>{t('error')}</Typography>
        </StyledStatusText>
      )}
      {status === COMPARE_JOB_STATUSES.IN_PROGRESS && (
        <StyledStatusText>
          <CircleProgress size={12} color={'primary'} thickness={5} />
          <Typography color={theme.palette.primary.main}>{t('inProgress')}</Typography>
        </StyledStatusText>
      )}
      {status === COMPARE_JOB_STATUSES.INCLUDE_FAILED && (
        <>
          <StyledStatusText>
            <IconCompleteCircle size={12} color={'success'} />
            <Typography color={theme.palette.success.main}>{t('completed')}</Typography>
            <IconErrorCircle size={12} color={'error'} />
          </StyledStatusText>
        </>
      )}
    </>
  );
};

export default CompareStatus;

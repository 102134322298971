import React, { useEffect } from 'react';
import { useQueryToGetCompareJobTask } from '@/hooks/queries/useQueryToGetCompareJobTask';
import { Button, CircleProgress, TableCell, TableRow } from '@mzc-cloudplex/core';
import { Grid, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import theme from '@/styles/theme';
import { IconCompleteCircle, IconDifferent, IconErrorCircle, IconPath } from '@mzc-cloudplex/icons';
import { alpha, Box } from '@mui/system';
import CdnIconName from '@/components/common/CdnIconName';

const CompareJobFileObjectItem = ({
  spaceId,
  projectId,
  jobId,
  fileObject,
  onItemClick,
  onChangeTask
}) => {
  const { t } = useTranslation('jobs');

  const {
    data: dataToGetCompareJobTask,
    isLoading: isLoadingToGetCompareJobTask,
    error: errorToGetCompareJobTask
  } = useQueryToGetCompareJobTask({
    spaceId: spaceId,
    projectId: projectId,
    jobId: jobId,
    taskId: fileObject.task.id
  });

  useEffect(() => {
    if (dataToGetCompareJobTask != null) onChangeTask(dataToGetCompareJobTask);
  }, [dataToGetCompareJobTask]);

  const selectStatus = (taskStatus, compareStatus) => {
    switch (taskStatus) {
      case 'PENDING':
        return (
          <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
            <Grid item>
              <CircleProgress
                size={12}
                color={'inherit'}
                thickness={5}
                style={{ marginTop: `3px` }}
              />
            </Grid>
            <Grid item>
              <Typography color={theme.palette.text.secondary}>{t('pending')}</Typography>
            </Grid>
          </Grid>
        );
      case 'IN_PROGRESS':
        return (
          <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
            <CircleProgress size={12} color={'primary'} thickness={5} />
            <Grid item>
              <Typography color={theme.palette.primary.main}>{t('inProgress')}</Typography>
            </Grid>
          </Grid>
        );
      case 'FAILED':
        return (
          <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
            <IconErrorCircle size={12} color={'error'} />
            <Grid item>
              <Typography color={theme.palette.error.main}>{t('failed')}</Typography>
            </Grid>
          </Grid>
        );
      case 'COMPLETED':
        switch (compareStatus) {
          case 'DIFFERENT':
            return (
              <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={12}
                  height={12}
                  borderRadius={12}
                  sx={{ backgroundColor: theme.palette.warning.main }}
                >
                  <IconDifferent size={12} htmlColor={'#fff'} />
                </Box>
                <Grid item>
                  <Typography color={theme.palette.warning.main}>{t('different')}</Typography>
                </Grid>
              </Grid>
            );
          case 'IDENTICAL':
            return (
              <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
                <IconCompleteCircle size={12} color={'success'} />
                <Grid item>
                  <Typography color={theme.palette.success.main}>{t('identical')}</Typography>
                </Grid>
              </Grid>
            );
          default:
            return <></>;
        }
      default:
        return <></>;
    }
  };
  return (
    <TableRow
      sx={{
        height: 55,
        backgroundColor:
          dataToGetCompareJobTask?.status === 'FAILED' || errorToGetCompareJobTask != null
            ? alpha(theme.palette.error[100], 0.24)
            : `inherit`,
        '&:hover': {
          backgroundColor:
            dataToGetCompareJobTask?.status === 'FAILED' || errorToGetCompareJobTask != null
              ? alpha(theme.palette.error[100], 0.24)
              : ``
        }
      }}
      onClick={() => onItemClick?.(dataToGetCompareJobTask)}
    >
      <TableCell>
        <Button
          variant={'solid'}
          color={'secondary'}
          sx={{ textAlign: `left`, alignItems: `flex-start`, color: theme.palette.text.primary }}
          onClick={() => onItemClick?.(dataToGetCompareJobTask)}
          startIcon={<IconPath size={16} htmlColor={theme.palette.grayscaleSecondary.light} />}
        >
          {fileObject.path}
        </Button>
      </TableCell>
      <TableCell>
        {isLoadingToGetCompareJobTask ? (
          <Grid container gap={1}>
            <Skeleton variant={'text'} width={100} height={22} />
          </Grid>
        ) : errorToGetCompareJobTask != null ? (
          <Grid container alignItems={'center'} gap={1}>
            <IconErrorCircle size={12} color={'error'} />
            <Typography color={'error'}>{t('error')}</Typography>
          </Grid>
        ) : (
          <Grid container gap={1}>
            <CdnIconName
              service={dataToGetCompareJobTask.cdn.service}
              size={'small'}
              isNameVisible={true}
            />
          </Grid>
        )}
      </TableCell>
      <TableCell>
        {isLoadingToGetCompareJobTask ? (
          <Grid container gap={1}>
            <Skeleton variant={'text'} width={100} height={16} />
          </Grid>
        ) : errorToGetCompareJobTask != null ? (
          <Grid container alignItems={'center'} gap={1}>
            <IconErrorCircle size={12} color={'error'} />
            <Typography color={'error'}>{t('error')}</Typography>
          </Grid>
        ) : (
          selectStatus(dataToGetCompareJobTask.status, dataToGetCompareJobTask.compareStatus)
        )}
      </TableCell>
    </TableRow>
  );
};

CompareJobFileObjectItem.propTypes = {
  spaceId: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  fileObject: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired
};

export default React.memo(CompareJobFileObjectItem);

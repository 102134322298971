import { useQueryToListMyCompareJobs } from '@/hooks/queries/useQueryToListMyCompareJobs';
import { useMemo, useState } from 'react';
import compareJobService from '@/services/job/compareJobService';

const useListMyCompareJobs = ({ spaceId }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const {
    isFetching: isFetchingToListMyCompareJobs,
    isLoading: isLoadingToListMyCompareJobs,
    data: dataToListMyCompareJobs,
    refetch: refetchToListMyCompareJobs
  } = useQueryToListMyCompareJobs({ spaceId: spaceId }, page - 1, pageSize, {
    onSuccess: () => compareJobService.updateLastLoadTime()
  });

  const jobs = useMemo(
    () =>
      (dataToListMyCompareJobs?.results ?? []).map((item) =>
        compareJobService.createViewModel(item)
      ),
    [dataToListMyCompareJobs]
  );

  return {
    jobs: jobs,
    isFetching: isFetchingToListMyCompareJobs,
    isLoading: isLoadingToListMyCompareJobs,
    page: page,
    setPage: setPage,
    pageSize: pageSize,
    setPageSize: setPageSize,
    totalCount: dataToListMyCompareJobs?.totalCount,
    refresh: refetchToListMyCompareJobs
  };
};

export default useListMyCompareJobs;

import { ROUTING_TYPES } from '@/services/routingPolicy/v1/routingPolicyService';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

export const useRoutingPolicyTypeProvider = () => {
  const { t } = useTranslation('routingPolicy');
  const routingPolicyTypeOptions = useMemo(
    () => [
      { label: t('simple'), value: ROUTING_TYPES.SIMPLE },
      { label: t('weighted'), value: ROUTING_TYPES.WEIGHTED },
      { label: t('geolocation'), value: ROUTING_TYPES.GEOCUSTOM }
    ],
    []
  );
  return {
    routingPolicyTypeOptions: routingPolicyTypeOptions,
    get: useCallback(
      (type) => routingPolicyTypeOptions.find((item) => item.value === type),
      []
    )
  };
};

import moment from 'moment';

export const COMPARE_JOB_STATUSES = {
  PREPARED: 'PREPARED',
  ALL_IDENTICAL: 'ALL_IDENTICAL',
  INCLUDE_DIFFERENT: 'INCLUDE_DIFFERENT',
  ALL_FAILED: 'ALL_FAILED',
  INCLUDE_FAILED: 'INCLUDE_FAILED',
  IN_PROGRESS: 'IN_PROGRESS'
};

const compareJobService = {
  updateLastLoadTime: () => {
    compareJobService.lastLoadTime = moment.utc().local().format('YYYY-MM-DD HH:mm:ss');
  },
  lastLoadTime: null,
  createViewModel: (job, cdns) => ({
    id: job?.id,
    project: {
      id: job?.project?.id
    },
    fileObjectCount: job?.fileObjectCount ?? 0,
    name: job?.name ?? '',
    cdns:
      job?.cdns?.map((cdn) => {
        const cdnSource = cdns?.find((item) => item.id === cdn.id);
        return { id: cdn.id, service: cdn.service ?? cdnSource?.service, name: cdnSource?.name };
      }) ?? [],
    resultInfo: {
      differentCount: job?.resultInfo?.differentCount ?? 0,
      identicalCount: job?.resultInfo?.identicalCount ?? 0,
      failedCount: job?.resultInfo?.failedCount ?? 0,
      inProgressCount: job?.resultInfo?.inProgressCount ?? 0,
      pendingCount: job?.resultInfo?.pendingCount ?? 0,
      totalCount: job?.resultInfo?.totalCount ?? 0,
      status:
        job?.resultInfo?.totalCount != null
        && job?.resultInfo?.pendingCount === job?.resultInfo?.totalCount
          ? COMPARE_JOB_STATUSES.PREPARED
          : job?.resultInfo?.identicalCount === job?.resultInfo?.totalCount
          ? COMPARE_JOB_STATUSES.ALL_IDENTICAL
          : job?.resultInfo?.identicalCount + job?.resultInfo?.differentCount
            === job?.resultInfo?.totalCount
          ? COMPARE_JOB_STATUSES.INCLUDE_DIFFERENT
          : job?.resultInfo?.failedCount === job?.resultInfo?.totalCount
          ? COMPARE_JOB_STATUSES.ALL_FAILED
          : job?.resultInfo?.identicalCount
              + job?.resultInfo?.differentCount
              + job?.resultInfo?.failedCount
            === job?.resultInfo?.totalCount
          ? COMPARE_JOB_STATUSES.INCLUDE_FAILED
          : COMPARE_JOB_STATUSES.IN_PROGRESS
    },
    creator: {
      ...job?.creator,
      name: job?.creator?.name ?? '',
      username: job?.creator?.username ?? ''
    },
    createdAt:
      job?.createdAt == null ? '' : moment.utc(job.createdAt).local().format('YYYY-MM-DD HH:mm:ss'),
    completedAt:
      job?.completedAt == null
        ? ''
        : moment.utc(job.completedAt).local().format('YYYY-MM-DD HH:mm:ss')
  })
};

export default compareJobService;

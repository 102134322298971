import enWelcome from '@/locales/en-us/welcome.json';
import koWelcome from '@/locales/ko-kr/welcome.json';

import enCommon from '@/locales/en-us/common.json';
import koCommon from '@/locales/ko-kr/common.json';

import enSummary from '@/locales/en-us/summary.json';
import koSummary from '@/locales/ko-kr/summary.json';

import enUser from '@/locales/en-us/users.json';
import koUser from '@/locales/ko-kr/users.json';

import enDns from '@/locales/en-us/dns.json';
import koDns from '@/locales/ko-kr/dns.json';

import enCdn from '@/locales/en-us/cdn.json';
import koCdn from '@/locales/ko-kr/cdn.json';

import enEndpoint from '@/locales/en-us/endpoint.json';
import koEndpoint from '@/locales/ko-kr/endpoint.json';

import enRoutingPolicy from '@/locales/en-us/routingPolicy.json';
import koRoutingPolicy from '@/locales/ko-kr/routingPolicy.json';

import enStorage from '@/locales/en-us/storage.json';
import koStorage from '@/locales/ko-kr/storage.json';

import enJobs from '@/locales/en-us/jobs.json';
import koJobs from '@/locales/ko-kr/jobs.json';

import enNotification from '@/locales/en-us/notification.json';
import koNotification from '@/locales/ko-kr/notification.json';

import enOriginStorage from '@/locales/en-us/originStorage.json';
import koOriginStorage from '@/locales/ko-kr/originStorage.json';

import enMetrics from '@/locales/en-us/cdnMetrics.json';
import koMetrics from '@/locales/ko-kr/cdnMetrics.json';

import enCredential from '@/locales/en-us/credential.json';
import koCredential from '@/locales/ko-kr/credential.json';

const locales = {
  'en-us': {
    welcome: enWelcome,
    common: enCommon,
    summary: enSummary,
    users: enUser,
    dns: enDns,
    cdn: enCdn,
    endpoint: enEndpoint,
    routingPolicy: enRoutingPolicy,
    storage: enStorage,
    jobs: enJobs,
    notification: enNotification,
    originStorage: enOriginStorage,
    cdnMetrics: enMetrics,
    credential: enCredential
  },
  'ko-kr': {
    welcome: koWelcome,
    common: koCommon,
    summary: koSummary,
    users: koUser,
    dns: koDns,
    cdn: koCdn,
    endpoint: koEndpoint,
    routingPolicy: koRoutingPolicy,
    storage: koStorage,
    jobs: koJobs,
    notification: koNotification,
    originStorage: koOriginStorage,
    cdnMetrics: koMetrics,
    credential: koCredential
  }
};

export default locales;

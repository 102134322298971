import React, { useMemo } from 'react';
import { ImageFolderSmallOn, IconFile, IconOpenInBrowserSmall } from '@mzc-cloudplex/icons';
import { Grid, Typography } from '@mui/material';
import { Button } from '@mzc-cloudplex/core';
import theme from '@/styles/theme';
import { Box } from '@mui/system';

const FileObjectName = ({
  name,
  path,
  total,
  iconVisible = true,
  maxWidth,
  minWidth,
  onClick,
  variant
}) => {
  const splitPath = path.split('/').filter((item) => (item?.length ?? 0) > 0);
  const objectName = useMemo(
    () =>
      name
      ?? (splitPath.length < 1 ? '' : splitPath[splitPath.length - 1])
        + (path.endsWith('/') ? '/' : ''),
    [name, path]
  );

  return (
    <>
      <LinkOrChildren
        onClick={onClick}
        icon={
          iconVisible === true && (
            <>
              {name != null ? (
                <></>
              ) : path.endsWith('/') ? (
                <ImageFolderSmallOn
                  width={16}
                  height={16}
                  onClick={onClick}
                  style={{ marginTop: `5px` }}
                />
              ) : (
                <IconFile
                  size={16}
                  htmlColor={'#9fa3b2'}
                  onClick={onClick}
                  style={{ marginTop: `5px` }}
                />
              )}
            </>
          )
        }
      >
        <Box
          style={{
            ...(minWidth !== null ? { minWidth: minWidth } : {}),
            ...(maxWidth !== null ? { maxWidth: maxWidth } : {}),
            overflowWrap: 'break-word'
          }}
        >
          <Typography
            variant={variant === 'header' ? 'h3' : 'subtitle1'}
            component={'span'}
            fontWeight={variant === 'overview' ? 400 : variant === 'header' ? 'bold' : 500}
          >
            {objectName}
          </Typography>
          {total > 1 && (
            <Typography
              variant={variant === 'header' ? 'h3' : 'subtitle1'}
              component={'span'}
              fontWeight={variant === 'overview' ? 400 : variant === 'header' ? 'bold' : 500}
            >
              {' '} and {total - 1} others
            </Typography>
          )}
        {variant === 'panel' && <IconOpenInBrowserSmall size={16} style={{ marginLeft: 8 }} />}
        </Box>
      </LinkOrChildren>
    </>
  );
};

const LinkOrChildren = ({ children, icon, onClick }) => {
  if (onClick != null) {
    const handleClick = () => {
      if (onClick != null) {
        onClick();
      }
    };
    return (
      <Button
        variant={'solid'}
        color={'secondary'}
        onClick={handleClick}
        sx={{ textAlign: `left`, alignItems: `flex-start`, color: theme.palette.text.primary }}
        startIcon={icon}
      >
        {children}
      </Button>
    );
  }

  return (
    <Grid component={'span'} container alignItems={'center'} gap={1}>
      {icon}
      {children}
    </Grid>
  );
};

export default FileObjectName;

import { useQuery } from 'react-query';
import { ApiFactory } from '@/utils/apiFactory';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';
import tryCatch from '@/services/infrastructure/tryCatch';

export const createKeyForGetCompareJobTask = ({ spaceId, projectId, jobId, taskId }) => [
  // `/spaces/${spaceId}/projects/${projectId}/compare-jobs/${jobId}`,
  `/spaces/${spaceId}/projects/${projectId}/compare-jobs/${jobId}/tasks/${taskId}`
];
export const useQueryToGetCompareJobTask = (
  { spaceId, projectId, jobId, taskId },
  options = null,
  exceptionHandler = exceptionProcessorForQuery()
) =>
  useQuery(
    createKeyForGetCompareJobTask({ spaceId, projectId, jobId, taskId }),
    async (context) =>
      tryCatch(async () => {
        const response = await ApiFactory.cpdV3({
          method: 'get',
          url: `/compare-jobs/${jobId}/tasks/${taskId}`,
          headers: {
            'cloudplex-space-id': spaceId,
            'cloudplex-project-id': projectId
          },
          signal: context.signal
        });
        return response.data;
      }, exceptionHandler),
    { ...options }
  );

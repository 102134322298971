import purgeJobService from '@/services/job/purgeJobService';
import { useQueryClient } from 'react-query';
import {
  createKeyForGetPurgeJob,
  useQueryToGetPurgeJob
} from '@/hooks/queries/useQueryToGetPurgeJob';
import { useQueryToListCdns } from '@/hooks/queries/useQueryToListCdns';
import { useContext, useEffect, useMemo } from 'react';
import { createKeyForGetPurgeJobTask } from '@/hooks/queries/useQueryToGetPurgeJobTask';
import { JOB_EVENTS } from '@/hooks/useEventObserver';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';

const usePurgeJobDetail = ({ spaceId, projectId, jobId, eventObserver }) => {
  const queryClient = useQueryClient();
  const purgeJobExceptionHandler = () => {
    const base = exceptionProcessorForQuery();
    return {
      handle: (error) => {
        if (error?.response?.status === 401) return true;
        return base.handle(error);
      }
    };
  };
  const {
    isLoading: isLoadingToGetPurgeJob,
    data: dataToGetPurgeJob,
    error: errorToGetPurgeJob
  } = useQueryToGetPurgeJob(
    {
      spaceId: spaceId,
      projectId: projectId,
      jobId: jobId
    },
    null,
    purgeJobExceptionHandler()
  );

  const { isLoading: isLoadingToListCdns, data: dataToListCdns } = useQueryToListCdns(
    {
      spaceId: spaceId,
      projectId: projectId
    },
    {},
    0,
    999,
    null,
    purgeJobExceptionHandler()
  );

  const isUnauthorized = errorToGetPurgeJob?.response?.status === 401;
  const isForbidden = errorToGetPurgeJob?.response?.status === 403;

  const isLoading = isLoadingToListCdns || isLoadingToGetPurgeJob;

  const targetCdns = useMemo(
    () =>
      dataToGetPurgeJob?.cdns?.map((cdn) => {
        const cdnSource = dataToListCdns?.results?.find((item) => item.id === cdn.id);
        return {
          ...cdn,
          service: cdn?.service ?? cdnSource?.service,
          name: cdnSource?.name
        };
      }),
    [dataToListCdns, dataToGetPurgeJob?.cdns]
  );

  const jobViewModel = useMemo(
    () => purgeJobService.createViewModel(dataToGetPurgeJob, targetCdns),
    [dataToGetPurgeJob, targetCdns]
  );

  const { subscribe, unsubscribe } = useContext(eventObserver.context);

  useEffect(() => {
    const callback = async (messageBody) => {
      if (messageBody.jobId === jobId)
        await queryClient.invalidateQueries(
          createKeyForGetPurgeJob({
            spaceId,
            projectId,
            jobId
          })
        );
      if (messageBody?.taskId != null)
        await queryClient.invalidateQueries(
          createKeyForGetPurgeJobTask({ spaceId, projectId, jobId, taskId: messageBody.taskId })
        );
    };
    subscribe(JOB_EVENTS.PURGE_JOB, callback);
    return () => unsubscribe(JOB_EVENTS.PURGE_JOB, callback);
  }, [spaceId, projectId, jobId]);

  return {
    jobViewModel,
    isLoading,
    isUnauthorized,
    isForbidden
  };
};

export default usePurgeJobDetail;

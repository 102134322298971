import { Paper as MuiPaper, styled } from '@mui/material';

export const HEADER_PANEL_CLOSED_HEIGHT = 54;
export const HEADER_PANEL_OPENED_HEIGHT = 224;

const openedMixin = (theme) => ({
  height: `${HEADER_PANEL_OPENED_HEIGHT}px`,
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
});

const closedMixin = (theme) => ({
  height: `${HEADER_PANEL_CLOSED_HEIGHT}px`,
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
});

export const PaperPanel = styled(MuiPaper, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  position: 'absolute',
  top: 60,
  width: '100%',
  zIndex: theme.zIndex.drawer,
  backgroundColor: '#F9F9FC',
  overflowY: 'hidden',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

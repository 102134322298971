import React, { useContext, useMemo } from 'react';
import { Typography, Toolbar, Divider } from '@mui/material';
import { IconButton, Tooltip } from '@mzc-cloudplex/core';
import { IconInfoCircle, IconLeave } from '@mzc-cloudplex/icons';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Info from '@/contexts/Info';
import {
  ClosedBody,
  Drawer,
  DrawerBody,
  DrawerHeader
} from '@/components/layouts/informationPanel/styles/informationPanel.styled';
import { getPanelDetailByInformationId } from '@/services/informationPanel/informationService';
import { Box } from '@mui/system';
import theme from '@/styles/theme';

const InformationPanel = () => {
  const { t } = useTranslation('common');
  const [searchParams, setSearchParams] = useSearchParams();
  const informationId = searchParams.get('info');
  const { latestId } = useContext(Info.context);
  const handleOpen = () => {
    searchParams.set('info', latestId);
    setSearchParams(searchParams);
  };
  const handleClose = () => {
    searchParams.delete('info');
    setSearchParams(searchParams);
  };

  const DetailComponent = getPanelDetailByInformationId(informationId);

  const title = useMemo(() => t(`infoLabel.${informationId}`), [informationId, t]);

  return (
    <Drawer anchor='right' variant={'permanent'} open={!!informationId}>
      <Toolbar />
      {!!DetailComponent && !!title ? (
        <>
          <DrawerHeader>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: `center`
              }}
            >
              <Typography component={'strong'} variant={'h4'}>
                {title}
              </Typography>
              <Tooltip title={t('label.close')}>
                <IconButton size={'medium'} circled onClick={handleClose}>
                  <IconLeave size={16} htmlColor={theme.palette.secondary.main} />
                </IconButton>
              </Tooltip>
            </Box>
          </DrawerHeader>
          <Divider />
          <DrawerBody>
            <DetailComponent />
          </DrawerBody>
        </>
      ) : (
        <ClosedBody>
          <Tooltip title={t('label.information')} placement={'left'}>
            <IconButton size={'medium'} circled onClick={handleOpen}>
              <IconInfoCircle size={16} htmlColor={theme.palette.secondary.main} />
            </IconButton>
          </Tooltip>
        </ClosedBody>
      )}
    </Drawer>
  );
};

export default InformationPanel;

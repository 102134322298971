import React, { createContext, useState } from 'react';

const InfoContext = createContext({
  latestId: null,
  setLatestId: () => {}
});

const InfoContextProvider = (props) => {
  const [latestId, setLatestId] = useState(null);
  return (
    <InfoContext.Provider
      {...props}
      value={{
        latestId: latestId,
        setLatestId: setLatestId
      }}
    />
  );
};

const Info = {
  context: InfoContext,
  Provider: InfoContextProvider
};

export default Info;

import { useContext, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import {
  createKeyForGetCompareJob,
  useQueryToGetCompareJob
} from '@/hooks/queries/useQueryToGetCompareJob';
import { useQueryToListCdns } from '@/hooks/queries/useQueryToListCdns';
import compareJobService from '@/services/job/compareJobService';
import { createKeyForGetCompareJobTask } from '@/hooks/queries/useQueryToGetCompareJobTask';
import { JOB_EVENTS } from '@/hooks/useEventObserver';
import { exceptionProcessorForQuery } from '@/services/infrastructure/exceptionProcessorForQuery';

const useCompareJobDetail = ({ spaceId, projectId, jobId, eventObserver }) => {
  const queryClient = useQueryClient();

  const compareJobExceptionHandler = () => {
    const base = exceptionProcessorForQuery();
    return {
      handle: (error) => {
        if (error?.response?.status === 401) return true;
        return base.handle(error);
      }
    };
  };
  const {
    isLoading: isLoadingToGetCompareJob,
    data: dataToGetCompareJob,
    error: errorToGetCompareJob
  } = useQueryToGetCompareJob(
    {
      spaceId: spaceId,
      projectId: projectId,
      jobId: jobId
    },
    null,
    compareJobExceptionHandler()
  );

  const { isLoading: isLoadingToListCdns, data: dataToListCdns } = useQueryToListCdns(
    {
      spaceId: spaceId,
      projectId: projectId
    },
    {},
    0,
    999,
    {},
    compareJobExceptionHandler()
  );

  const isForbidden = errorToGetCompareJob?.response?.status === 403;
  const isUnauthorized = errorToGetCompareJob?.response?.status === 401;

  const isLoading = isLoadingToListCdns || isLoadingToGetCompareJob;

  const targetCdns = useMemo(
    () =>
      dataToGetCompareJob?.cdns.map((cdn) => {
        const cdnSource = dataToListCdns?.results?.find((item) => item.id === cdn.id);
        return {
          ...cdn,
          service: cdn?.service ?? cdnSource?.service,
          name: cdnSource?.name
        };
      }),
    [dataToListCdns, dataToGetCompareJob?.cdns]
  );

  const jobViewModel = useMemo(
    () => compareJobService.createViewModel(dataToGetCompareJob, targetCdns),
    [dataToGetCompareJob, targetCdns]
  );

  const { subscribe, unsubscribe } = useContext(eventObserver.context);

  useEffect(() => {
    const callback = async (messageBody) => {
      if (messageBody.jobId === jobId)
        await queryClient.invalidateQueries(
          createKeyForGetCompareJob({
            spaceId,
            projectId,
            jobId
          })
        );
      if (messageBody?.taskId != null)
        await queryClient.invalidateQueries(
          createKeyForGetCompareJobTask({ spaceId, projectId, jobId, taskId: messageBody.taskId })
        );
    };
    subscribe(JOB_EVENTS.COMPARE_JOB, callback);
    return () => unsubscribe(JOB_EVENTS.COMPARE_JOB, callback);
  }, [spaceId, projectId, jobId]);

  return {
    jobViewModel,
    isLoading,
    isForbidden,
    isUnauthorized
  };
};
export default useCompareJobDetail;

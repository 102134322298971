import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mzc-cloudplex/core';
import CompareJobFileObjectItem from '@/components/manage/job/common/compare/CompareJobFileObjectItem';
import PropTypes from 'prop-types';
import useCompareJobFileObjectList from '@/components/myJob/hooks/useCompareJobFileObjectList';
import CircleSpinner from '@/components/common/spinners/CircleSpinner';
import { Grid, Box, Typography } from '@mui/material';
import ListTool from '@/components/common/ListTool';
import {
  Autocomplete,
  TextField
} from '@/components/manage/notification/common/ChipsInput/ChipsInput.styled';

const CompareJobFileObjectList = ({ spaceId, job, onItemClick, onChangeTask }) => {
  const { t } = useTranslation('jobs');
  const {
    isLoading,
    totalCount,
    fileObjects,
    page,
    pageSize,
    setPage,
    setPageSize,
    statuses,
    setStatuses
  } = useCompareJobFileObjectList({ spaceId: spaceId, projectId: job.project.id, jobId: job.id });

  const getStatusOptions = [
    { value: 'IDENTICAL', label: t('identical') },
    { value: 'DIFFERENT', label: t('different') },
    { value: 'FAILED', label: t('failed') },
    { value: 'PENDING', label: t('pending') },
    { value: 'IN_PROGRESS', label: t('inProgress') }
  ];

  useEffect(() => {
    setStatuses([]);
  }, []);

  const handleStatusChange = (_, selectedOptions) => {
    setStatuses(selectedOptions.map((option) => option.value));
    refresh();
  };

  return (
    <>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs>
          <Autocomplete
            multiple
            disableCloseOnSelect
            value={getStatusOptions.filter((option) => statuses.includes(option.value))}
            options={getStatusOptions}
            getOptionLabel={(option) => `${option.label}`}
            onChange={handleStatusChange}
            renderTags={(tags) =>
              tags.length > 0 ? (
                <>
                  <Typography style={{ marginLeft: '10px' }}>{tags[0]?.label ?? ''}</Typography>
                  {tags.length > 1 && (
                    <Chip style={{ height: 'auto' }} label={`+${tags.length - 1}`} />
                  )}
                </>
              ) : null
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox color={'default'} style={{ marginRight: 8 }} checked={selected} />
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps }}
                variant='outlined'
                placeholder={t('selectStatus')}
              />
            )}
            style={{ width: 230 }}
          />
        </Grid>
        <Grid item xs>
          <ListTool
            divider={false}
            totalCount={totalCount}
            page={page}
            pageSize={pageSize}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
          />
        </Grid>
      </Grid>
      <TableContainer
        id='table-container'
        sx={{ flex: `1`, overflow: `auto`, border: `none`, minHeight: 400 }}
      >
        <Table stickyHeader size={'small'}>
          <colgroup>
            <col />
            <col width={300} />
            <col width={180} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>{t('path')}</TableCell>
              <TableCell>CDN(s)</TableCell>
              <TableCell>{t('status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan='4' align={'center'}>
                  <CircleSpinner />
                </TableCell>
              </TableRow>
            ) : !isLoading && fileObjects.length < 1 ? (
              <TableRow>
                <TableCell colSpan='4' align={'center'}>
                  <Box sx={{ py: 3 }}>{t('message.fileObjectNotExist')}</Box>
                </TableCell>
              </TableRow>
            ) : (
              fileObjects.map((item, index) => (
                <CompareJobFileObjectItem
                  key={index}
                  spaceId={spaceId}
                  projectId={job.project.id}
                  jobId={job.id}
                  fileObject={item}
                  onItemClick={onItemClick}
                  onChangeTask={onChangeTask}
                  targetCdns={job.cdns}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

CompareJobFileObjectList.propTypes = {
  spaceId: PropTypes.string.isRequired,
  job: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired
};

export default CompareJobFileObjectList;

const production = {
  domain: 'cloudplex.so',
  startApplicationUrl: 'https://start.cloudplex.so',
  cpd: {
    apiV3: 'https://delivery.cloudplex.megazoneapis.com/v3',
    apiV4: 'https://delivery.cloudplex.megazoneapis.com/v4',
    channelV1: 'https://channel.cloudplex.me/v1',
    adminV1: 'https://admin.delivery.cloudplex.megazoneapis.com/v1'
  },
  pops: {
    host: 'https://login.megazone.com',
    clientId: 'oAAEablZVqMXpX2PXQzaF7mIXhEL2c',
    redirectUrl: '/megazone/login',
    initiateLoginUrl: '/login'
  }
};

export default production;

const development = {
  domain: 'cloudplex.so',
  startApplicationUrl: 'https://start.dev.cloudplex.so',
  cpd: {
    apiV3: 'https://2rehhaw4s4.execute-api.ap-northeast-2.amazonaws.com/dev/v3',
    apiV4: 'https://2rehhaw4s4.execute-api.ap-northeast-2.amazonaws.com/dev/v4',
    channelV1: 'https://channel.dev.cloudplex.me/v1',
    adminV1: 'https://mi7sugd80e.execute-api.ap-northeast-2.amazonaws.com/dev/v1',
  },
  pops: {
    host: 'https://login.megazone.com',
    clientId: '9mhKvXyu0ojiqkwDtb0M3lVLurkQmG',
    redirectUrl: '/megazone/login',
    initiateLoginUrl: '/login'
  }
};

export default development;

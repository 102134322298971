import React, { useEffect } from 'react';
import { useQueryToGetPurgeJobTask } from '@/hooks/queries/useQueryToGetPurgeJobTask';
import { Button, CircleProgress, TableCell, TableRow } from '@mzc-cloudplex/core';
import { Grid, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '@/styles/theme';
import { IconCompleteCircle, IconErrorCircle, IconPath } from '@mzc-cloudplex/icons';
import { alpha } from '@mui/system';
import CdnIconName from '@/components/common/CdnIconName';

const PurgeJobFileObjectItem = ({ spaceId, job, fileObject, onItemClick, onChangeTask }) => {
  const { t } = useTranslation('jobs');
  const {
    data: dataToGetPurgeJobTask,
    isLoading: isLoadingToGetPurgeJobTask,
    error: errorToGetPurgeJobTask
  } = useQueryToGetPurgeJobTask(
    {
      spaceId: spaceId,
      projectId: job.project.id,
      jobId: job.id,
      taskId: fileObject.task.id
    },
    {
      enabled: !!job
    }
  );

  useEffect(() => {
    if (dataToGetPurgeJobTask != null) onChangeTask(dataToGetPurgeJobTask);
  }, [dataToGetPurgeJobTask]);

  const selectStatus = (status) => {
    switch (status) {
      case 'PENDING':
        return (
          <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
            <CircleProgress size={12} color={'inherit'} thickness={5} />
            <Grid item>
              <Typography color={theme.palette.text.secondary}>{t('pending')}</Typography>
            </Grid>
          </Grid>
        );
      case 'IN_PROGRESS':
        return (
          <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
            <CircleProgress size={12} color={'primary'} thickness={5} />
            <Grid item>
              <Typography color={theme.palette.primary.main}>{t('inProgress')}</Typography>
            </Grid>
          </Grid>
        );
      case 'COMPLETED':
        return (
          <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
            <IconCompleteCircle size={12} color={'success'} />
            <Grid item>
              <Typography color={theme.palette.success.main}>{t('completed')}</Typography>
            </Grid>
          </Grid>
        );
      case 'FAILED':
        return (
          <Grid container gap={1} display={'inline-flex'} alignItems={'center'} width={'auto'}>
            <IconErrorCircle size={12} color={'error'} />
            <Grid item>
              <Typography color={theme.palette.error.main}>{t('failed')}</Typography>
            </Grid>
          </Grid>
        );
      default:
        return '';
    }
  };

  return (
    <TableRow
      sx={{
        backgroundColor:
          dataToGetPurgeJobTask?.status === 'FAILED' || errorToGetPurgeJobTask != null
            ? alpha(theme.palette.error[100], 0.24)
            : `inherit`,
        '&:hover': {
          backgroundColor:
            dataToGetPurgeJobTask?.status === 'FAILED' || errorToGetPurgeJobTask != null
              ? alpha(theme.palette.error[100], 0.24)
              : ``
        }
      }}
      onClick={() => onItemClick?.(dataToGetPurgeJobTask)}
    >
      <TableCell>
        <Button
          variant={'solid'}
          color={'secondary'}
          sx={{ textAlign: `left`, alignItems: `flex-start`, color: theme.palette.text.primary }}
          onClick={() => onItemClick?.(dataToGetPurgeJobTask)}
          startIcon={<IconPath size={16} htmlColor={theme.palette.grayscaleSecondary.light} />}
        >
          {fileObject.path}
        </Button>
      </TableCell>
      <TableCell>
        {isLoadingToGetPurgeJobTask ? (
          <Grid container gap={1}>
            <Skeleton variant={'text'} width={100} height={22} />
          </Grid>
        ) : errorToGetPurgeJobTask != null ? (
          <Grid container alignItems={'center'} gap={1}>
            <IconErrorCircle size={12} color={'error'} />
            <Typography color={'error'}>{t('error')}</Typography>
          </Grid>
        ) : (
          <Grid container gap={1}>
            <CdnIconName
              service={dataToGetPurgeJobTask.cdn.service}
              size={'small'}
              isNameVisible={true}
            />
          </Grid>
        )}
      </TableCell>
      <TableCell>
        {isLoadingToGetPurgeJobTask ? (
          <Grid container gap={1}>
            <Skeleton variant={'text'} width={100} height={16} />
          </Grid>
        ) : errorToGetPurgeJobTask != null ? (
          <Grid container alignItems={'center'} gap={1}>
            <IconErrorCircle size={12} color={'error'} />
            <Typography color={'error'}>{t('error')}</Typography>
          </Grid>
        ) : (
          selectStatus(dataToGetPurgeJobTask.status)
        )}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(PurgeJobFileObjectItem);

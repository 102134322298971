import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Toolbar } from '@mui/material';
import { Container, Documents } from '@/components/layouts/layout.styled';
import styleTheme from '@/styles/theme';
import MyInfo from '@/contexts/MyInfo';
import Info from '@/contexts/Info';
import MyEventObserver from '@/contexts/MyEventObserver';
import Footer from '@/components/layouts/footer/Footer';
import InformationPanel from '@/components/layouts/informationPanel/InformationPanel';
import HeaderPanel from '@/components/layouts/headerPanel/HeaderPanel';
import { HEADER_PANEL_CLOSED_HEIGHT } from '@/components/layouts/headerPanel/styles/headerPanel.styled';
import { styled } from '@mui/system';
import HeaderForMonitoring from '@/components/layouts/header/HeaderForMonitoring';

export const MonitoringContent = styled('div')(() => ({
  flex: 1,
  display: `flex`,
  flexDirection: `column`,
  flexWrap: `nowrap`,
  justifyContent: `flex-start`,
  alignItems: `stretch`,
  boxSizing: `border-box`,
  paddingTop: `${HEADER_PANEL_CLOSED_HEIGHT}px`,
  backgroundColor: '#f3f3f9',
  height: `100%`,
  minWidth: 0,
  overflow: `auto`
}));

const LayoutForMonitoring = ({ children }) => (
    <MyInfo.Provider>
      <Info.Provider>
        <MyEventObserver.Provider>
          <DndProvider backend={HTML5Backend}>
            <Documents theme={styleTheme}>
              <HeaderForMonitoring/>
              <Container>
                <Toolbar />
                <HeaderPanel />
                <MonitoringContent>{children}</MonitoringContent>
                <Footer />
              </Container>
            </Documents>
          </DndProvider>
        </MyEventObserver.Provider>
      </Info.Provider>
    </MyInfo.Provider>
  );

export default LayoutForMonitoring;

import validateInput from '@/utils/validateInput';
import { LIMIT_BYTES } from '@/constants';
import { activityService } from '@/services/activityService';

export const CDN_SERVICES = {
  AWS_CLOUDFRONT: 'AWS_CLOUDFRONT',
  AKAMAI: 'AKAMAI',
  LIMELIGHT: 'LIMELIGHT',
  AZURE_EDGIO: 'AZURE_EDGIO',
  KT_SOLBOX: 'KT_SOLBOX',
  ALIBABA_CLOUD_CDN: 'ALIBABA_CLOUD_CDN',
  TENCENT_CLOUD_CDN: 'TENCENT_CLOUD_CDN',
  TENCENT_CLOUD_EDGEONE: 'TENCENT_CLOUD_EDGEONE',
  TENCENT_CSS: 'TENCENT_CSS',
  LG_UPLUS: 'LG_UPLUS',
  GOOGLE_CLOUD_CDN: 'GOOGLE_CLOUD_CDN',
  CLOUDFLARE_CDN: 'CLOUDFLARE_CDN',
  HYOSUNG_ITX_CDN: 'HYOSUNG_ITX_CDN',
  BYTEPLUS_CDN: 'BYTEPLUS_CDN',
  LG_NIMBUS: 'LG_NIMBUS',
  HUAWEI_CLOUD_CDN: 'HUAWEI_CLOUD_CDN',
  SK_BROADBAND_CDN: 'SK_BROADBAND_CDN',
  CLOUDPLEX_MEGAZONE_CDN: 'CLOUDPLEX_MEGAZONE_CDN',
  CUSTOM: 'CUSTOM'
};

export const CDN_ALIAS_SERVICES = {
  cf: CDN_SERVICES.AWS_CLOUDFRONT,
  ak: CDN_SERVICES.AKAMAI,
  ll: CDN_SERVICES.LIMELIGHT,
  az: CDN_SERVICES.AZURE_EDGIO,
  kt: CDN_SERVICES.KT_SOLBOX,
  al: CDN_SERVICES.ALIBABA_CLOUD_CDN,
  tc: CDN_SERVICES.TENCENT_CLOUD_CDN,
  lg: CDN_SERVICES.LG_UPLUS,
  gg: CDN_SERVICES.GOOGLE_CLOUD_CDN,
  fl: CDN_SERVICES.CLOUDFLARE_CDN,
  hs: CDN_SERVICES.HYOSUNG_ITX_CDN,
  te: CDN_SERVICES.TENCENT_CLOUD_EDGEONE,
  bp: CDN_SERVICES.BYTEPLUS_CDN,
  ln: CDN_SERVICES.LG_NIMBUS,
  hw: CDN_SERVICES.HUAWEI_CLOUD_CDN,
  sb: CDN_SERVICES.SK_BROADBAND_CDN,
  mc: CDN_SERVICES.CLOUDPLEX_MEGAZONE_CDN,
  ts: CDN_SERVICES.TENCENT_CSS,
  cu: CDN_SERVICES.CUSTOM
};

export const ROUTE53_REGIONS = {
  US_EAST_1: 'US_EAST_1',
  US_WEST_1: 'US_WEST_1',
  US_WEST_2: 'US_WEST_2',
  EU_WEST_1: 'EU_WEST_1',
  AP_SOUTHEAST_1: 'AP_SOUTHEAST_1',
  AP_SOUTHEAST_2: 'AP_SOUTHEAST_2',
  AP_NORTHEAST_1: 'AP_NORTHEAST_1',
  SA_EAST_1: 'SA_EAST_1'
};

const validate = (value, rules) => {
  const result = validateInput(value, rules);
  // noinspection JSIncompatibleTypesComparison
  return result == null || Object.keys(result).length < 1;
};

const awsCloudfrontService = {
  name: 'Amazon CloudFront',
  platform: 'AWS',
  service: CDN_SERVICES.AWS_CLOUDFRONT,
  v1Hint: 'AWS_CLOUDFRONT_V1',
  v2Hint: 'AWS_CLOUDFRONT_V2',
  init: () => ({
    distribution: {
      id: ''
    },
    cname: ''
  }),
  validationRules: {
    distributionId: { required: true },
    cname: { required: true, url: true }
  },
  validate: (cdn) =>
    validate(cdn.data.distribution.id, awsCloudfrontService.validationRules.distributionId)
    && validate(cdn.data.cname, awsCloudfrontService.validationRules.cname)
};

const googleCdnService = {
  name: 'Google Cloud CDN',
  platform: 'GOOGLE_CLOUD',
  service: CDN_SERVICES.GOOGLE_CLOUD_CDN,
  v1Hint: 'GOOGLE_CLOUD_V1',
  v2Hint: null,
  init: () => ({
    projectNo: '',
    projectId: '',
    projectName: '',
    loadBalancer: '',
    anycastIp: '',
    protocols: ''
  }),
  validationRules: {
    projectNo: { required: true },
    projectId: { required: true },
    projectName: { required: true },
    loadBalancer: { required: true },
    anycastIp: { required: true }
  },
  validate: (cdn) =>
    validate(cdn.data.projectNo, googleCdnService.validationRules.projectNo)
    && validate(cdn.data.projectId, googleCdnService.validationRules.projectId)
    && validate(cdn.data.projectName, googleCdnService.validationRules.projectName)
    && validate(cdn.data.loadBalancer, googleCdnService.validationRules.loadBalancer)
    && validate(cdn.data.anycastIp, googleCdnService.validationRules.anycastIp)
    && cdn.data.protocols.length > 0
};

const akamaiCdnService = {
  name: 'Akamai CDN',
  platform: 'AKAMAI',
  service: 'AKAMAI',
  v1Hint: 'AKAMAI_V1',
  v2Hint: 'AKAMAI_V2',
  init: () => ({
    cpCode: '',
    cname: ''
  }),
  validationRules: {
    cpCode: { required: true },
    cname: { required: true, url: true }
  },
  validate: (cdn) =>
    validate(cdn.data.cpCode, akamaiCdnService.validationRules.cpCode)
    && validate(cdn.data.cname, akamaiCdnService.validationRules.cname)
};

const azureCdnService = {
  name: 'Azure CDN',
  platform: 'AZURE',
  service: CDN_SERVICES.AZURE_EDGIO,
  v1Hint: 'AZURE_EDGIO_V1',
  v2Hint: 'AZURE_EDGIO_V2', //AZURE_V2
  init: () => ({
    resourceGroup: '',
    profileName: '',
    endpointName: '',
    cname: '',
    subscriptionId: ''
  }),
  validationRules: {
    resourceGroup: { required: true },
    profileName: { required: true },
    endpointName: { required: true },
    cname: { required: true, url: true },
    subscriptionId: { required: true, uuid: true }
  },
  validate: (cdn) =>
    validate(cdn.data.resourceGroup, azureCdnService.validationRules.resourceGroup)
    && validate(cdn.data.profileName, azureCdnService.validationRules.profileName)
    && validate(cdn.data.endpointName, azureCdnService.validationRules.endpointName)
    && validate(cdn.data.cname, azureCdnService.validationRules.cname)
    && validate(cdn.data.subscriptionId, azureCdnService.validationRules.subscriptionId)
};

const edgioCdnService = {
  name: 'Edgio CDN',
  platform: 'LIMELIGHT',
  service: CDN_SERVICES.LIMELIGHT,
  v1Hint: 'LIMELIGHT_V1',
  v2Hint: 'LIMELIGHT_V2',
  init: () => ({
    protocols: ['HTTP', 'HTTPS'],
    cname: ''
  }),
  validationRules: {
    cname: { required: true, url: true }
  },
  validate: (cdn) => validate(cdn.data.cname, edgioCdnService.validationRules.cname)
};

const alibabaCdnService = {
  name: 'Alibaba Cloud CDN',
  platform: 'ALIBABA_CLOUD',
  service: CDN_SERVICES.ALIBABA_CLOUD_CDN,
  v1Hint: 'ALIBABA_CLOUD_V1',
  v2Hint: 'ALIBABA_CLOUD_V2',
  init: () => ({
    cname: ''
  }),
  validationRules: {
    cname: {
      required: true,
      url: true
    }
  },
  validate: (cdn) => validate(cdn.data.cname, alibabaCdnService.validationRules.cname)
};

const tencentCdnService = {
  name: 'Tencent Cloud CDN',
  platform: 'TENCENT_CLOUD',
  service: CDN_SERVICES.TENCENT_CLOUD_CDN,
  v1Hint: 'TENCENT_CLOUD_V1',
  v2Hint: 'TENCENT_CLOUD_V2',
  init: () => ({
    cname: ''
  }),
  validationRules: {
    cname: { required: true, url: true }
  },
  validate: (cdn) => validate(cdn.data.cname, tencentCdnService.validationRules.cname)
};

const tencentEdgeOneService = {
  name: 'Tencent Cloud EdgeOne',
  platform: 'TENCENT_CLOUD',
  service: CDN_SERVICES.TENCENT_CLOUD_EDGEONE,
  v1Hint: 'TENCENT_CLOUD_EDGEONE_V1',
  v2Hint: 'TENCENT_CLOUD_EDGEONE_V2',
  init: () => ({
    cname: '',
    zoneId: ''
  }),
  validationRules: {
    cname: { required: true, url: true },
    zoneId: { required: true }
  },
  validate: (cdn) =>
    validate(cdn.data.cname, tencentEdgeOneService.validationRules.cname)
    && validate(cdn.data.zoneId, tencentEdgeOneService.validationRules.zoneId)
};

const tencentCssService = {
  name: 'Tencent CSS',
  platform: 'TENCENT_CLOUD',
  service: CDN_SERVICES.TENCENT_CSS,
  v1Hint: 'TENCENT_CSS_V1',
  v2Hint: 'TENCENT_CSS_V2',
  init: () => ({
    cname: ''
  }),
  validationRules: {
    cname: { required: true, url: true }
  },
  validate: (cdn) => validate(cdn.data.cname, tencentCssService.validationRules.cname)
};

const ktSolboxService = {
  name: 'KT Solbox',
  platform: 'KT',
  service: CDN_SERVICES.KT_SOLBOX,
  v1Hint: 'KT_SOLBOX_V1',
  v2Hint: 'KT_SOLBOX_V2',
  init: () => ({
    cname: '',
    serviceName: ''
  }),
  validationRules: {
    cname: { required: true, url: true },
    serviceName: { required: true }
  },
  validate: (cdn) =>
    validate(cdn.data.cname, ktSolboxService.validationRules.cname)
    && validate(cdn.data.serviceName, ktSolboxService.validationRules.serviceName)
};

const lgSolboxService = {
  name: 'LGU+ Solbox',
  platform: 'LG_UPLUS',
  service: CDN_SERVICES.LG_UPLUS,
  v1Hint: 'LG_UPLUS_V1',
  v2Hint: 'LG_UPLUS_V2',
  init: () => ({
    cname: '',
    serviceName: ''
  }),
  validationRules: {
    cname: { required: true, url: true },
    serviceName: { required: true }
  },
  validate: (cdn) =>
    validate(cdn.data.cname, lgSolboxService.validationRules.cname)
    && validate(cdn.data.serviceName, lgSolboxService.validationRules.serviceName)
};

const cloudflareCdnService = {
  name: 'Cloudflare CDN',
  platform: 'CLOUDFLARE',
  service: CDN_SERVICES.CLOUDFLARE_CDN,
  v1Hint: 'CLOUDFLARE_V1',
  v2Hint: 'CLOUDFLARE_V2',
  init: () => ({
    cname: ''
  }),
  validationRules: {
    cname: { required: true, url: true }
  },
  validate: (cdn) => validate(cdn.data.cname, cloudflareCdnService.validationRules.cname)
};

const hyosungCdnService = {
  name: 'Hyosung ITX CDN',
  platform: 'HYOSUNG_ITX',
  service: CDN_SERVICES.HYOSUNG_ITX_CDN,
  v1Hint: 'HYOSUNG_ITX_V1',
  v2Hint: null,
  init: () => ({
    cname: '',
    tid: '',
    lid: null
  }),
  validationRules: {
    cname: { required: true, url: true },
    tid: { required: true },
    lid: {}
  },
  validate: (cdn) =>
    validate(cdn.data.cname, hyosungCdnService.validationRules.cname)
    && validate(cdn.data.tid, hyosungCdnService.validationRules.tid)
    && validate(cdn.data.tid, hyosungCdnService.validationRules.lid)
};

const bytePlusCdnService = {
  name: 'BytePlus CDN',
  platform: 'BYTEPLUS',
  service: CDN_SERVICES.BYTEPLUS_CDN,
  v1Hint: 'BYTEPLUS_CDN_V1',
  v2Hint: 'BYTEPLUS_CDN_V2',
  init: () => ({
    cname: ''
  }),
  validationRules: {
    cname: { required: true, url: true }
  },
  validate: (cdn) => validate(cdn.data.cname, hyosungCdnService.validationRules.cname)
};

const lgNimbusCdnService = {
  name: 'LGU+ Nimbus',
  platform: 'LG_UPLUS',
  service: CDN_SERVICES.LG_NIMBUS,
  v1Hint: 'LG_NIMBUS_V1',
  v2Hint: null, //'LG_NIMBUS_V2',
  init: () => ({
    cname: ''
  }),
  validationRules: {
    cname: { required: true, url: true }
  },
  validate: (cdn) => validate(cdn.data.cname, hyosungCdnService.validationRules.cname)
};

const huaweiCdnService = {
  name: 'Huawei Cloud CDN',
  platform: 'HUAWEI_CLOUD',
  service: CDN_SERVICES.HUAWEI_CLOUD_CDN,
  v1Hint: 'HUAWEI_CLOUD_V1',
  v2Hint: 'HUAWEI_CLOUD_V2',
  init: () => ({
    cname: ''
  }),
  validationRules: {
    cname: { required: true, url: true }
  },
  validate: (cdn) => validate(cdn.data.cname, huaweiCdnService.validationRules.cname)
};

const skBroadbandService = {
  name: 'SK Broadband CDN',
  platform: 'SK_BROADBAND',
  service: CDN_SERVICES.SK_BROADBAND_CDN,
  v1Hint: 'SK_BROADBAND_CDN_V1',
  v2Hint: null,
  init: () => ({
    cname: ''
  }),
  validationRules: {
    cname: { required: true, url: true }
  },
  validate: (cdn) => validate(cdn.data.cname, skBroadbandService.validationRules.cname)
};

const cloudplexService = {
  name: 'Megazone CDN',
  platform: 'CLOUDPLEX',
  service: CDN_SERVICES.CLOUDPLEX_MEGAZONE_CDN,
  v1Hint: 'CLOUDPLEX_MEGAZONE_CDN_V1',
  v2Hint: 'CLOUDPLEX_MEGAZONE_CDN_V2',
  init: () => ({
    cname: '',
    zoneId: ''
  }),
  validationRules: {
    cname: { required: true, url: true },
    zoneId: { required: true }
  },
  validate: (cdn) =>
    validate(cdn.data.cname, cloudplexService.validationRules.cname)
    && validate(cdn.data.zoneId, cloudplexService.validationRules.zoneId)
};

const customService = {
  name: 'Custom',
  platform: 'CUSTOM',
  service: CDN_SERVICES.CUSTOM,
  v1Hint: 'CUSTOM_V1',
  v2Hint: null,
  init: (serviceName) => ({
    serviceName: serviceName,
    record: '',
    recordType: ''
  }),
  validationRules: {
    serviceName: { required: true, maxByte: LIMIT_BYTES.name },
    record: {
      CNAME: { required: true, url: true },
      A: { required: true, ipV4: true },
      AAAA: { required: true, ipV6: true }
    },
    recordType: { required: true }
  },
  validate: (cdn) =>
    validate(cdn.data.serviceName, customService.validationRules.serviceName)
    && validate(cdn.data.recordType, customService.validationRules.recordType)
    && validate(cdn.data.record, customService.validationRules.record[cdn.data.recordType])
};

const dirtyCheckerDictionary = {};

const cdnService = {
  awsCloudfront: awsCloudfrontService,
  googleCdn: googleCdnService,
  akamaiCdn: akamaiCdnService,
  azureCdn: azureCdnService,
  edgioCdn: edgioCdnService,
  alibabaCdn: alibabaCdnService,
  tencentCdn: tencentCdnService,
  tencentEdgeOne: tencentEdgeOneService,
  tencentCss: tencentCssService,
  ktSolbox: ktSolboxService,
  lgSolbox: lgSolboxService,
  cloudflareCdn: cloudflareCdnService,
  hyosungCdn: hyosungCdnService,
  bytePlusCdn: bytePlusCdnService,
  lgNimbus: lgNimbusCdnService,
  huaweiCdn: huaweiCdnService,
  skBroadbandCdn: skBroadbandService,
  cloudplexMegazoneCdn: cloudplexService,
  custom: customService,
  getByCdnService: (cdn) => {
    switch (cdn) {
      case CDN_SERVICES.AWS_CLOUDFRONT:
        return cdnService.awsCloudfront;
      case CDN_SERVICES.GOOGLE_CLOUD_CDN:
        return cdnService.googleCdn;
      case CDN_SERVICES.AKAMAI:
        return cdnService.akamaiCdn;
      case CDN_SERVICES.AZURE_EDGIO:
        return cdnService.azureCdn;
      case CDN_SERVICES.LIMELIGHT:
        return cdnService.edgioCdn;
      case CDN_SERVICES.ALIBABA_CLOUD_CDN:
        return cdnService.alibabaCdn;
      case CDN_SERVICES.TENCENT_CLOUD_CDN:
        return cdnService.tencentCdn;
      case CDN_SERVICES.TENCENT_CLOUD_EDGEONE:
        return cdnService.tencentEdgeOne;
      case CDN_SERVICES.TENCENT_CSS:
        return cdnService.tencentCss;
      case CDN_SERVICES.KT_SOLBOX:
        return cdnService.ktSolbox;
      case CDN_SERVICES.LG_UPLUS:
        return cdnService.lgSolbox;
      case CDN_SERVICES.CLOUDFLARE_CDN:
        return cdnService.cloudflareCdn;
      case CDN_SERVICES.HYOSUNG_ITX_CDN:
        return cdnService.hyosungCdn;
      case CDN_SERVICES.BYTEPLUS_CDN:
        return cdnService.bytePlusCdn;
      case CDN_SERVICES.LG_NIMBUS:
        return cdnService.lgNimbus;
      case CDN_SERVICES.HUAWEI_CLOUD_CDN:
        return cdnService.huaweiCdn;
      case CDN_SERVICES.SK_BROADBAND_CDN:
        return cdnService.skBroadbandCdn;
      case CDN_SERVICES.CLOUDPLEX_MEGAZONE_CDN:
        return cdnService.cloudplexMegazoneCdn;
      case CDN_SERVICES.CUSTOM:
        return cdnService.custom;
      default:
        return null;
    }
  },
  createViewModel: (cdn) => ({
    ...cdn,
    cloudProductCredential: {
      ...cdn?.cloudProductCredential,
      identifier: cdn?.cloudProductCredential?.identifier
    },
    provider: {
      ...cdn?.provider,
      isResourceProvider: () => cdn?.provider?.status != null,
      isNormal: () => cdn?.provider?.status === 'NORMAL',
      isInvalidCredential: () => cdn?.provider?.status === 'INVALID_CREDENTIALS',
      isWrongResource: () => cdn?.provider?.status === 'WRONG_RESOURCE',
      isVersion2: () =>
        cdn?.provider?.hint === 'AWS_CLOUDFRONT_V2'
        || cdn?.provider?.hint === 'AKAMAI_V2'
        || cdn?.provider?.hint === 'ALIBABA_CLOUD_V2'
        || cdn?.provider?.hint === 'AZURE_EDGIO_V2'
        || cdn?.provider?.hint === 'CLOUDFLARE_V2'
        || cdn?.provider?.hint === 'KT_SOLBOX_V2'
        || cdn?.provider?.hint === 'LG_UPLUS_V2'
        || cdn?.provider?.hint === 'TENCENT_CLOUD_V2'
        || cdn?.provider?.hint === 'LIMELIGHT_V2'
        || cdn?.provider?.hint === 'TENCENT_EDGEONE_V2'
        || cdn?.provider?.hint === 'TENCENT_CSS_V2'
        || cdn?.provider?.hint === 'CLOUDPLEX_MEGAZONE_CDN_V2'
    },
    useMetrics: cdn?.useMetrics ?? false,
    isCustom: () => cdn?.service === CDN_SERVICES.CUSTOM,
    ...activityService.createViewModel(cdn)
  }),
  dirtyChecker: {
    get: (key) => dirtyCheckerDictionary[key] != null,
    set: (key) => (dirtyCheckerDictionary[key] = Date.now()),
    clear: (key) => delete dirtyCheckerDictionary[key]
  }
};

export default cdnService;

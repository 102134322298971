import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileObjectName from '@/components/manage/job/common/FileObjectName';
import ProjectName from '@/components/myJob/tabs/ProjectName';
import Pagination from '@/components/myJob/tabs/Pagination';
import purgeJobService from '@/services/job/purgeJobService';
import { alpha, Box } from '@mui/system';
import { LinearProgress } from '@mzc-cloudplex/core';
import { IconEmptyData } from '@mzc-cloudplex/icons';
import { Divider, Grid, Typography, ListItem } from '@mui/material';
import theme from '@/styles/theme';
import { List, ListItemText } from '@/components/myJob/tabs/styles/tabs.styled';
import PurgeStatus from '@/components/common/statuses/PurgeStatus';
import useListMyPurgeJobs from '@/components/myJob/hooks/useListMyPurgeJobs';
import { MyJobSkeleton } from '@/components/myJob/tabs/MyJobSkeleton';
import { MyJobResult } from '@/components/myJob/tabs/MyJobResult';
import MyEventObserver from '@/contexts/MyEventObserver';
import usePurgeJobDetail from '@/components/manage/job/hooks/usePurgeJobDetail';
import CdnIconName from '@/components/common/CdnIconName';

//my job purge tab
const PurgeTab = ({ spaceId, showDetailModal }) => {
  const { jobs, isLoading, page, pageSize, setPage, totalCount, refresh } =
    useListMyPurgeJobs({ spaceId: spaceId });
  const [topDivider, setTopDivider] = useState(false);

  const handleScrolling = (e) => {
    if (e === undefined) return;
    const { scrollTop } = e.currentTarget;

    setTopDivider(scrollTop !== 0);
  };

  return (
    <Grid container height={`100%`} flexDirection={'column'} flexWrap={'nowrap'}>
      <Grid item>
        <Grid
          container
          alignItems={`center`}
          justifyContent={`space-between`}
          p={1.5}
          sx={{
            boxShadow: topDivider ? `0 3px 6px 0 rgba(0, 0, 0, 0.16);` : `none`
          }}
        >
          <Grid item>
            <Grid container alignItems={`center`}>
              <Grid item>
                <Typography variant={'body1'} component={'span'}>
                  {purgeJobService.lastLoadTime}
                </Typography>
              </Grid>
              <Grid item>
                <Pagination
                  disabled={isLoading}
                  page={page}
                  pageSize={pageSize}
                  onChange={(page) => setPage(page)}
                  totalCount={totalCount}
                  refresh={refresh}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid item xs pl={3} pr={3} pt={1.5} sx={{ overflowY: `auto` }} onScroll={handleScrolling}>
        {isLoading ? (
          <MyJobSkeleton />
        ) : (jobs?.length ?? 0) < 1 ? (
          <Empty />
        ) : (
          jobs.map((job, jobIndex) => (
            <JobItem
              job={job}
              key={`job-${jobIndex}`}
              spaceId={spaceId}
              onShowDetail={showDetailModal}
            />
          ))
        )}
      </Grid>
    </Grid>
  );
};

//my job purge item
const JobItem = React.memo(({ spaceId, job: listItem, onShowDetail }) => {
  const { t } = useTranslation('jobs');

  const {
    jobViewModel: fetchedJobItem,
    isForbidden,
    isLoading,
    isUnauthorized
  } = usePurgeJobDetail({
    spaceId,
    projectId: listItem.project.id,
    jobId: listItem.id,
    eventObserver: MyEventObserver
  });

  const jobViewModel = useMemo(
    () => (fetchedJobItem.id == null || isLoading ? listItem : fetchedJobItem),
    [listItem, fetchedJobItem]
  );

  return (
    <div>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <PurgeStatus status={jobViewModel.resultInfo.status} />
      </Grid>
      <Divider sx={{ marginTop: 1 }} />
      <Box sx={{ paddingBottom: 4 }}>
        <List>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              disableTypography
              primary={<Typography className={'MuiListItemText-primary'}>{t('object')}</Typography>}
              secondary={
                <FileObjectName
                  variant={'panel'}
                  name={jobViewModel.isPurgeAll() ? t('purgeAll') : null}
                  path={jobViewModel.isPurgeAll() ? '/*' : jobViewModel.name}
                  total={jobViewModel.fileObjectCount}
                  onClick={
                    isForbidden || isUnauthorized
                      ? null
                      : () => onShowDetail?.({ job: jobViewModel })
                  }
                />
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              disableTypography
              primary={
                <Typography className={'MuiListItemText-primary'}>{t('project')}</Typography>
              }
              secondary={
                <div style={{ maxWidth: '400px', overflowWrap: 'break-word' }}>
                  <ProjectName spaceId={spaceId} projectId={jobViewModel.project.id} />
                </div>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              disableTypography
              primary={<Typography className={'MuiListItemText-primary'}>CDN(s)</Typography>}
              secondary={
                <Grid container>
                  {jobViewModel.cdns.map((cdn) => (
                    <Grid item key={cdn.id}>
                      <CdnIconName service={cdn.service} />
                    </Grid>
                  ))}
                </Grid>
              }
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              primary={t('requestedAt')}
              secondary={jobViewModel.createdAt}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              sx={{ my: 0.5 }}
              primary={t('completedAt')}
              secondary={jobViewModel.completedAt ? jobViewModel.completedAt : '-'}
            />
          </ListItem>
        </List>
        <Box
          sx={{
            mt: 1,
            backgroundColor: alpha(theme.palette.grayscaleSecondary[50], 0.5),
            padding: 2,
            borderRadius: `2px`
          }}
        >
          <Box display={'flex'} alignItems={'center'} gap={0.5}>
            <Typography variant={'body2'} fontWeight={'bold'} color={'primary'}>
              {jobViewModel.resultInfo.completedCount + jobViewModel.resultInfo.failedCount}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              / {jobViewModel.resultInfo.totalCount}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {t('task')}{' '}
              {jobViewModel.resultInfo.status === 'IN_PROGRESS'
                ? t('inProgress')
                : jobViewModel.resultInfo.status === 'PREPARED'
                ? t('pending')
                : jobViewModel.resultInfo.status === 'FAILED'
                ? t('failed')
                : t('completed')}
            </Typography>
          </Box>
          <Box pt={1} pb={1.5}>
            <LinearProgress
              height={6}
              value={
                jobViewModel.resultInfo.status === 'PREPARED'
                  ? 0
                  : ((jobViewModel.resultInfo.completedCount + jobViewModel.resultInfo.failedCount)
                      / jobViewModel.resultInfo.totalCount)
                    * 100
              }
              variant='determinate'
            />
          </Box>
          <MyJobResult job={jobViewModel} />
        </Box>
      </Box>
    </div>
  );
});

const Empty = () => {
  const { t } = useTranslation('jobs');
  return (
    <Box pt={4} pb={4} textAlign={'center'}>
      <IconEmptyData size={58} style={{ color: theme.palette.grayscale[300] }} />
      <Typography mt={2}>{t('noOperation')}.</Typography>
    </Box>
  );
};

export default PurgeTab;
